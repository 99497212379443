import { Inject, Injectable, PLATFORM_ID, Renderer2 } from "@angular/core";
import { AnalyticsService } from "@utils/services/analytics/analytics.service";
import { AdService } from "@lib-modules/ads/services/ad.service";
import { PageService } from "../../../../../pages/page/page.service";
import { AdEventType } from "@lib-modules/ads/models/ad-event-type";
import { PaywallType } from '@lib/models/paywall-type/paywall-type';
import { isPlatformServer } from "@angular/common";

export const AllowedLinkAttribute = "data-webview-allowed";
export const NoPushAttribute = "data-webview-nopush";

export enum WebviewPlatform {
  Android = "app-android",
  iOS = "app-ios",
}

@Injectable({
  providedIn: "root",
})
export class WebviewService {

  public static allowNavigation = false;
  public static isWebview = false;
  public static platform: WebviewPlatform;

  private lastPageViewUrl: string;

  public static sendPageViewEvent(url: string): void {
    console.log("[Webview Navigation] Send page view event for", url);
    window.postMessage(JSON.stringify({ name: "fan.at.pageView", url }), "*");
  }

  public static sendNavigationEvent(url: string, noPush = false): void {
    console.log("[Webview Navigation] Send navigation event for", url);
    window.postMessage(JSON.stringify({ name: "fan.at.navigation", url, noPush }), "*");
  }

  public static sendPaywallEvent(paywallType: PaywallType): void {
    console.log("[Webview Paywall] Send paywall event");
    window.postMessage(JSON.stringify({ name: "fan.at.paywall", paywallType: paywallType }), "*");
  }

  public static sendOpenRegistrationEvent(): void {
    console.log("[Webview Paywall] Send open registration event");
    window.postMessage(JSON.stringify({ name: "fan.at.register" }), "*");
  }

  public static sendRouteChangeEvent(path: string): void {
    console.log("[Webview Navigation] Send route change event for", path);
    window.postMessage(
      JSON.stringify({ name: "fan.at.routeChange", routeChange: path }),
      "*"
    );
  }

  public static sendAdEvent(type: AdEventType, slotId: string): void {
    console.log("[Webview Navigation] Send ad event ", type, slotId);
    window.postMessage(
      JSON.stringify({
        name: "fan.at.adEvent",
        adEvent: type,
        adSlotId: slotId,
      }),
      "*"
    );
  }

  public static sendFullscreenEvent(fullscreen: boolean): void {
    window.postMessage(
      JSON.stringify({ name: "fan.at.fullscreen", fullscreen }),
      "*"
    );
  }

  public static sendNavigateBackEvent(): void {
    window.postMessage(
      JSON.stringify({ name: "fan.at.navigateBack"}),
      "*"
    );
  }

  constructor(
    @Inject(PLATFORM_ID) protected platformId: any,
    private analyticsService: AnalyticsService,
    private adService: AdService
  ) {}

  public init(renderer: Renderer2): void {
    this.prepareWebview(renderer);
    this.registerEventHandlers();
  }

  private prepareWebview(renderer: Renderer2): void {
    renderer.addClass(document.body, "webview");
  }

  private registerEventHandlers(): void {
    this.analyticsService.pageViewEvent.subscribe((url) => {
      WebviewService.sendPageViewEvent(url);
      this.lastPageViewUrl = url;
    });
    this.adService.adEvent.subscribe((adEvent) => {
      WebviewService.sendAdEvent(adEvent.type, adEvent.adSlotId);
    });
    window.addEventListener("message", this.handleMessage.bind(this));
    window.addEventListener("click", this.handleWindowClick.bind(this));
  }

  private handleWindowClick(event: PointerEvent): void {
    if (WebviewService.allowNavigation) {
      return;
    }
    // Get event targets and find first link element
    const closestLink = event
      .composedPath()
      .find(
        (target: HTMLElement) => target.tagName === "A"
      ) as HTMLAnchorElement;
    if (
      !closestLink?.href?.length ||
      closestLink?.href?.startsWith("https://trc.taboola.com")
    ) {
      return;
    }
    if (closestLink.getAttribute(AllowedLinkAttribute) === "true") {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    const noPush = closestLink.getAttribute(NoPushAttribute) === "true";
    WebviewService.sendNavigationEvent(closestLink.href, noPush);
  }

  private handleMessage(event: MessageEvent): void {
    if (event?.data === "fan.at.triggerPI") {
      console.log("[Webview] Page view event received");
      this.analyticsService.sendPageView(this.lastPageViewUrl, null, true);
      return;
    }
    if (event?.data !== "fan.at.refresh") {
      return;
    }
    this.adService.refreshAll(true);
    this.analyticsService.sendPageView(this.lastPageViewUrl);
    PageService.refreshContent();
  }

  public isWebview(): boolean {
    if (isPlatformServer(this.platformId)) {
      return false;
    }
    const navigator = window.navigator as any;
    const standalone = navigator.standalone,
      webView = navigator._isWebView,
      userAgent = navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);

    // iOS webview has standalone === true, but no "safari" in user agent
    // _isWebView is set in index.html
    if (ios && (standalone || webView) && !safari) {
      return true;
    }
    // Android and fanat webview
    return (
      userAgent.includes("wv") || userAgent.includes("fanat-wv") || webView
    );
  }
}
